import { queryCache, useMutation, useQuery, useInfiniteQuery } from "react-query";
import queryWithToken, {
  create,
  deleteById,
  findById,
  updateById,
  updateOrCreate,
  find,
} from "./index";

import { get } from "lodash-es";

// ⚠️ ALSO USED IN ROUTE PATH TO IDENTIFY RESSOURCE ON BACK END API ⚠️
// You need to bind this key to the function passed to useMutation
const ressourceKey = "users";
//

export function useQueryUserById(id) {
  return useQuery(id && [ressourceKey, id], findById);
}

export function useQueryUsers(filters) {
  const { data, ...rest } = useQuery(
    [ressourceKey, filters],
    () =>
      queryWithToken(`${process.env.REACT_APP_API_URL}/${ressourceKey}`, {
        method: "GET",
        params: {
          page: null,
          pagination: false,
          ...filters,
        },
      }),
    {
      refetchOnWindowFocus: false,
    }
  );

  return {
    data: get(data, "hydra:member"),
    totalItems: get(data, "hydra:totalItems"),
    ...rest,
  };
}

export function useQueryUsersInfinite(filters) {
  return useInfiniteQuery([ressourceKey, filters], find, {
    getFetchMore: (lastGroup) => {
      const nextUrl = lastGroup["hydra:view"]["hydra:next"];
      const match = /page=(\d+)/.exec(nextUrl);
      if (match) {
        return match[1];
      }

      return false;
    },
    refetchOnWindowFocus: false,
  });
}

export function useCreateUser() {
  return useMutation(create.bind(null, ressourceKey), {
    throwOnError: true,
    onMutate: () => {
      queryCache.cancelQueries(ressourceKey);
    },
    onSuccess: (data) => {
      queryCache.setQueryData([ressourceKey, data.id], data);
      queryCache.refetchQueries(ressourceKey);
    },
  });
}

export function useUpdateUser() {
  return useMutation(updateById.bind(null, ressourceKey), {
    throwOnError: true,
    // When mutate is called:
    onMutate: () => {
      queryCache.cancelQueries(ressourceKey);
    },
    onSuccess: () => {
      queryCache.refetchQueries(ressourceKey);
      queryCache.refetchQueries(["last_work_accident-popin"]);
    },
  });
}

const updatePermissionByUserId = (key, { id, data }) => {
    return queryWithToken(
        `${process.env.REACT_APP_API_URL}/${ressourceKey}/${id}/permission`, {
            method: "PATCH",
            data,
        });
}

export function useUpdateUserPermission() {
    return useMutation(updatePermissionByUserId.bind(null, ressourceKey), {
        throwOnError: true,
        // When mutate is called:
        onMutate: () => {
            queryCache.cancelQueries(ressourceKey);
        },
        onSuccess: () => {
            queryCache.refetchQueries(ressourceKey);
            queryCache.refetchQueries(['collaborators']);
        },
    });
}

export function useCreateOrUpdateUser() {
  return useMutation(updateOrCreate.bind(null, ressourceKey), {
    throwOnError: true,
    // When mutate is called:
    onMutate: () => {
      queryCache.cancelQueries(ressourceKey);
    },
    onSuccess: () => {
      queryCache.refetchQueries(ressourceKey);
    },
  });
}

export function useDeleteUser() {
  return useMutation(deleteById.bind(null, ressourceKey), {
    onSuccess: () => {
      queryCache.refetchQueries(ressourceKey);
    },
  });
}

const queryUserMood = (_, id) => {
  return queryWithToken(
    `${process.env.REACT_APP_API_URL}/${id}/moods_current_year`,
    {
      method: "GET",
    }
  );
};

/**
 * @param {Number} id - userId
 * @returns promise
 */
export function useGetuserMood(id) {
  return useQuery(id && ["moods_current_year", id], queryUserMood);
}

export function useGetUserOrganizationDoc(id) {
  return useQuery(id && ["organization_document", id], () => {
    return queryWithToken(
    `${process.env.REACT_APP_API_URL}/${id}/organization_document`,
    {
      method: "GET",
    }
   );
  });
}

export function useGetUserLastWorkAccidentPopin(id) {
  return useQuery(id && ["last_work_accident-popin", id], () => {
    return queryWithToken(
      `${process.env.REACT_APP_API_URL}/${id}/work_accident_popin`,
      {
        method: "GET",
      }
    );
  });
}

export function useGetUserAlerts(id, filters) {
  const { data, ...rest } = useQuery(
    [ressourceKey, "alerts", filters],
    () =>
      queryWithToken(
        `${process.env.REACT_APP_API_URL}/${ressourceKey}/${id}/alerts`,
        {
          method: "GET",
          params: {
            page: null,
            pagination: false,
            ...filters,
          },
        }
      ),
    {
      refetchOnWindowFocus: false,
    }
  );
  return {
    data: get(data, "hydra:member"),
    ...rest,
  };
}

export function useGetUserArchivedAlerts(id) {
  const { data, ...rest } = useQuery(
    [ressourceKey, "archived_alerts"],
    () =>
      queryWithToken(
        `${process.env.REACT_APP_API_URL}/${ressourceKey}/${id}/archived_alerts`,
        {
          method: "GET",
          params: {
            page: null,
            pagination: false,
          },
        }
      ),
    {
      refetchOnWindowFocus: false,
    }
  );

  return {
    data,
    ...rest,
  };
}

export function useGetUserYearsMessages(id) {
  const { data, ...rest } = useQuery(
    [ressourceKey, "years_messages"],
    () =>
      queryWithToken(
        `${process.env.REACT_APP_API_URL}/${ressourceKey}/${id}/years_messages`,
        {
          method: "GET",
          params: {
            page: null,
            pagination: false,
          },
        }
      ),
    {
      refetchOnWindowFocus: false,
    }
  );

  return {
    data,
    ...rest,
  };
}

export function useGetUserYearsEvaluations(id) {
  const { data, ...rest } = useQuery(
    [ressourceKey, "years_evaluations"],
    () =>
      queryWithToken(
        `${process.env.REACT_APP_API_URL}/${ressourceKey}/${id}/years_evaluations`,
        {
          method: "GET",
          params: {
            page: null,
            pagination: false,
          },
        }
      ),
    {
      refetchOnWindowFocus: false,
    }
  );

  return {
    data,
    ...rest,
  };
}

export function useGetUserMessagesNotRead(id) {
  const { data, ...rest } = useQuery(
    [ressourceKey, "messages_not_read"],
    () =>
      queryWithToken(
        `${process.env.REACT_APP_API_URL}/${ressourceKey}/${id}/messages_not_read`,
        {
          method: "GET",
          params: {
            page: null,
            pagination: false,
          },
        }
      ),
    {
      refetchOnWindowFocus: false,
    }
  );

  return {
    data,
    ...rest,
  };
}

export function useGetUserRequests(id) {
  const { data, ...rest } = useQuery(
    [ressourceKey, "assigned_requests"],
    () =>
      queryWithToken(
        `${process.env.REACT_APP_API_URL}/${ressourceKey}/${id}/assigned_requests`,
        {
          method: "GET",
          params: {
            page: null,
            pagination: false,
          },
        }
      ),
    {
      refetchOnWindowFocus: false,
    }
  );

  return {
    data: get(data, "hydra:member"),
    ...rest,
  };
}

export function useGetUserApprovedRequests(id) {
  const { data, ...rest } = useQuery(
    [ressourceKey, "assigned_requests_approved"],
    () =>
      queryWithToken(
        `${process.env.REACT_APP_API_URL}/${ressourceKey}/${id}/assigned_requests_approved`,
        {
          method: "GET",
          params: {
            page: null,
            pagination: false,
          },
        }
      ),
    {
      refetchOnWindowFocus: false,
    }
  );

  return {
    data: get(data, "hydra:member"),
    ...rest,
  };
}

export function useGetUserHomeMessage(id) {
  const { data, ...rest } = useQuery(
    [ressourceKey, "user_home_message"],
    () =>
      queryWithToken(
        `${process.env.REACT_APP_API_URL}/${ressourceKey}/${id}/home_message`,
        {
          method: "GET",
          params: {
            page: null,
            pagination: false,
          },
        }
      ),
    {
      refetchOnWindowFocus: false,
    }
  );

  return {
    data: get(data, "hydra:member"),
    ...rest,
  };
}

export function useGetUserStartedProcessTask(id, filters) {
  const { data, ...rest } = useQuery(
    [ressourceKey, "started_process_tasks", filters],
    () =>
      queryWithToken(
        `${process.env.REACT_APP_API_URL}/${ressourceKey}/${id}/started_process_tasks`,
        {
          method: "GET",
          params: {
            page: null,
            pagination: false,
            ...filters,
          },
        }
      ),
    {
      refetchOnWindowFocus: false,
    }
  );
  return {
    data: get(data, "hydra:member"),
    ...rest,
  };
}

export function useGetUserAbsenceNotifications(id, filters) {
  const { data, ...rest } = useQuery(
    [ressourceKey, "notifications", filters, id],
    () =>
      queryWithToken(
        `${process.env.REACT_APP_API_URL}/${ressourceKey}/${id}/notifications`,
        {
          method: "GET",
          params: {
            page: null,
            pagination: false,
            ...filters,
          },
        }
      ),
    {
      refetchOnWindowFocus: false,
    }
  );

  return {
    data: get(data, "hydra:member"),
    ...rest,
  };
}

export function useCountUserAbsenceNotifications(id, filters) {
  const { data, ...rest } = useQuery(
    [ressourceKey, "notifications", filters, id],
    () =>
      queryWithToken(
        `${process.env.REACT_APP_API_URL}/${ressourceKey}/${id}/notifications/count`,
        {
          method: "GET",
          params: {
            page: null,
            pagination: false,
            ...filters,
          },
        }
      ),
    {
      refetchOnWindowFocus: false,
    }
  );

  return {
    data: data,
    ...rest,
  };
}

export function useImpersonateOnUser(id) {
  return useMutation(findById.bind(null, "impersonate"), {
    throwOnError: true,
    // When mutate is called:
    onMutate: () => {
      queryCache.cancelQueries(ressourceKey);
    },
    onSuccess: () => {
      queryCache.refetchQueries(ressourceKey);
    },
  });
}
