import Block from "../Block";
import { Link } from "react-router-dom";
import React from "react";
import { useGetEvaluationsToStart } from "../../api/collaborator";
import { size } from "lodash-es";
import Button from "../Button";
import { useDesktop } from "../../hooks/useDesktop";
import { useCollaboratorId } from "../../contexts/permissions";
import { EVALUATION_STATUS_COMPLETED } from "../../utils/evaluationStatus";

export default function EvaluationBlock() {
  const currentLastYear = new Date();
  currentLastYear.setFullYear(currentLastYear.getFullYear() - 1); // moins 1 an
  const collaboratorId = useCollaboratorId();

  const { data: evaluations } = useGetEvaluationsToStart(collaboratorId, {
    "createdAt[after]": currentLastYear,
    status: EVALUATION_STATUS_COMPLETED,
  });

  const isDesktop = useDesktop();
  const nbEvaluations = size(evaluations);

  return (
    <Block className="text-center flex-grow bg-white">
      <Link
        to={isDesktop ? `evaluations` : `/`}
        className="flex flex-col flex-auto items-center justify-between flex-grow h-full"
      >
        <div
          className={`bg-green-600 inline-flex text-white text-3xl rounded-full font-bold w-16 h-16 items-center justify-center`}
        >
          {nbEvaluations}
        </div>
        {nbEvaluations ? (
            <span
              className="unread bg-red-700 rounded-full text-white text-xs absolute w-5 h-5 ml-1 -mt-3"
              style={{ marginLeft: "43px", marginTop: "5px" }}
            >
              &nbsp;
            </span>
          ) : null}
        <div className="mt-3 text-xl font-black">
          {`Évaluation${nbEvaluations > 1 ? "s" : ""} à faire`}
        </div>
        <div className="mt-2 text-base font-light">{"\u00A0"}</div>
        {isDesktop && (<Button
          className={`font-bold p-1 focus:outline-none text-black bg-purple-500 text-white w-full block`}
        >
          voir tout
        </Button>
        )}
      </Link>
    </Block>
  );
}
