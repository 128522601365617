import React, { useState } from "react";
import { flatMap, get, map, pull, size } from "lodash-es";
import { useDebounce, useMap, useToggle } from "react-use";
import Alert from "../../components/Alert";
import ExpansionPanel from "../../components/ExpansionPanel";
import { Helmet } from "react-helmet";
import InfiniteScroll from "react-infinite-scroller";
import { Link } from "react-router-dom";
import Modal from "../../components/Modal";
import {
  usePermissionsChecker,
} from "../../contexts/permissions";
import Spinner from "../../components/Spinner";
import { useDesktop } from "../../hooks/useDesktop";
import { useQueryWorkAccidentsInfinite } from "../../api/workAccident";
import CreateWorkAccidentForm from "../../forms/CreateWorkAccidentForm";
import { useQueryAgencies } from "../../api/agencies";
import { ReactComponent as FilterIcon } from "../../svgs/filters.svg";
import CheckboxGroup from "../../components/CheckboxGroup";
import DatePicker from "react-datepicker";
import { getFirstnameLastname, getFirstnameLastnameJob, getOnlyJob } from "../../utils/names";
import { HeaderBar } from "../../components/HeaderBar";
import {CSVLink} from "react-csv";

function InlineBLockContent({ children, className = "" }) {
  return (
    <div className={`px-4 flex items-center ${className}`}>{children}</div>
  );
}

function RequalifiedWorkaccidentMobileCard({ workAccident }) {
  const canDelete = usePermissionsChecker({
    permissions: ["kdix.actions.work_accident.create"],
  });
  const canCreate = usePermissionsChecker({
    permissions: [
      "kdix.actions.work_accident.edit",
      "kdix.actions.work_accident.edit.agency",
      "kdix.actions.work_accident.edit.department",
      "kdix.actions.work_accident.edit.service",
      "kdix.actions.work_accident.edit.own",
    ],
  });
  
    return (
    <ExpansionPanel
      key={workAccident.id}
      title={getFirstnameLastnameJob(workAccident, "victim")}
      subtitle={get(workAccident, "agency.label")}
    >
      <CreateWorkAccidentForm workAccident={workAccident} readOnly />
      { canDelete && canCreate && <Link
        className="btn mt-6 w-full block text-center"
        to={canDelete?`/prevention/accident/${workAccident.id}`:`#`}
      >
        Modifier
      </Link>
      }
    </ExpansionPanel>
  );
}

function RequalifiedWorkaccidentsList({
  query,
  dateRange: { endDate, startDate },
  filters,
}) {
  const isDesktop = useDesktop();
  const filterDate =
    endDate !== null
      ? {
          "date[after]": new Date(startDate),
          "date[before]": new Date(endDate),
        }
      : {};
  const { data, fetchMore, canFetchMore } = useQueryWorkAccidentsInfinite({
    pagination: false,
    orSearch_victim_fullname: query,
    accepted: false,
    ...filters,
    ...filterDate,
  });
  
  const canDelete = usePermissionsChecker({
    permissions: ["kdix.actions.work_accident.create"],
  });

  if (size(data) <= 0) {
    return (
      <div className="mt-12">
        <Alert type="warning" message="aucun accident" />
      </div>
    );
  }

  const workAccidents = flatMap(data, (page) => page["hydra:member"]);
  const gridTemplateColumn = "5% 20.5% 20.5% 12.5% 12.5% 12.5% 12.5%";

  const csvHeaders = [
    {label: "N°", key: "id"},
    {label: "Victime", key: "victimFullName"},
    {label: "Emploi", key: "victimJob"},
    {label: "Matricule", key: "victimRegistrationNum"},
    {label: "Site", key: "agency.label"},
    {label: "Déclarant", key: "declarer"},
    {label: "Jour de l'accident", key: "dateFR"},
    {label: "Type d'accident", key: "typeAT"},
    {label: "Lieu", key: "localisationAT"},
    {label: "Localisation corporelle", key: "bodyArea"},
    {label: "Jours d'arrêt", key: "numberOfDaysOff"},
    {label: "Date de l'enquête", key: "dateInvestigationFR"},
    {label: "Description", key: "description"},
    {label: "Actions", key: "actions"},
  ];

  const csvData = map(workAccidents, (item) => {
    return {
      ...item,
      victimFullName: getFirstnameLastname(item, "victim"),
      victimJob: getOnlyJob(item, "victim"),
      victimRegistrationNum: item.victim.registrationNumber,
      declarer: getFirstnameLastname(item, "declarer"),
      dateFR: item.date ? new Date(item.date).toLocaleDateString() : "",
      typeAT: get(item, "type.label", ""),
      localisationAT: get(item, "location.label", ""),
      bodyArea: get(item, "bodyArea.label", ""),
      dateInvestigationFR: item.dateInvestigation
          ? new Date(item.dateInvestigation).toLocaleDateString()
          : "",
      description: get(item, "description", "").replaceAll("\"", "-"),
      actions: get(item, "actions", "").replaceAll("\"", "-"),
    };
  });

  return (
      <div className="relative">
        <div className="absolute -top-16 py-4 right-0">
          {canDelete && (
              <CSVLink
                 filename="accidents_requalifiés.csv"
                 className="btn-export"
                 headers={csvHeaders}
                 data={csvData}
              >
                Exporter
              </CSVLink>
           )}
        </div>
      {isDesktop && (
        <div
          className={`grid divide-x text-white divide-white bg-gray-500 shadow mb-2 py-2 sticky top-tab-header always-front`}
          style={{
            gridTemplateColumns: gridTemplateColumn,
          }}
        >
          <InlineBLockContent>N°</InlineBLockContent>
          <InlineBLockContent>Victime</InlineBLockContent>
          <InlineBLockContent>Site</InlineBLockContent>
          <InlineBLockContent>Jour de l'accident</InlineBLockContent>
          <InlineBLockContent>Jours d'arrêt</InlineBLockContent>
          <InlineBLockContent>Date de l'enquête</InlineBLockContent>
        </div>
      )}

      <InfiniteScroll
        pageStart={1}
        initialLoad={false}
        loadMore={() => {
          fetchMore();
        }}
        hasMore={canFetchMore !== false}
        loader={
          <div key={0} className=" relative">
            <Spinner />
          </div>
        }
      >
        {isDesktop
          ? map(workAccidents, (node) => {
              return (
                <Link
                  to={canDelete?`/prevention/accident/${node.id}`:`#`}
                  key={node.id}
                  className={`grid divide-x divide-gray-100 bg-white hover:bg-purple-50 shadow mb-2 py-2`}
                  style={{
                    gridTemplateColumns: gridTemplateColumn,
                  }}
                >
                  <InlineBLockContent>{node.id}</InlineBLockContent>
                  <InlineBLockContent>
                    {getFirstnameLastnameJob(node, "victim")}
                  </InlineBLockContent>
                  <InlineBLockContent>
                    {get(node, "agency.label", "")}
                  </InlineBLockContent>
                  <InlineBLockContent>
                    {node.date ? new Date(node.date).toLocaleDateString() : ""}
                  </InlineBLockContent>
                  <InlineBLockContent>
                    {get(node, "numberOfDaysOff")}
                  </InlineBLockContent>
                  <InlineBLockContent>
                    {node.dateInvestigation
                      ? new Date(node.dateInvestigation).toLocaleDateString()
                      : ""}
                  </InlineBLockContent>
                </Link>
              );
            })
          : map(workAccidents, (node) => (
              <RequalifiedWorkaccidentMobileCard
                key={node.id}
                workAccident={node}
              />
            ))}
      </InfiniteScroll>
    </div>
  );
}

function RequalifiedWorkaccidents() {
  const [query, setQuery] = useState("");
  const [debouncedQuery, setDebouncedQuery] = useState("");
  const [dateRange, setDateRange] = useState({
    startDate: new Date(),
    endDate: null,
  });
  const [
    filters,
    { set: setFilter, remove: removeFilter, reset: resetFilters },
  ] = useMap({});
  const [FiltersOpen, toggleFilters] = useToggle(false);
  const [,] = useDebounce(
    () => {
      setDebouncedQuery(query);
    },
    500,
    [query]
  );

  const { data: agencies } = useQueryAgencies();
  const filtersList = [
    {
      label: "Site",
      key: "agency",
      options: map(agencies, (node) => ({
        label: node.label,
        value: node["@id"],
        id: node["@id"],
      })),
    },
  ];

  React.useEffect(() => {
            var _paq = window._paq = window._paq || [];
            _paq.push(["setDocumentTitle", document.domain + "/" + document.title]);
            _paq.push(['trackPageView']);
            _paq.push(['enableLinkTracking']);
       
            var u="//matomo.kdix.pockost.com/";
            _paq.push(['setTrackerUrl', u+'matomo.php']);
            _paq.push(['setSiteId', '1']);
            var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
            g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
    }, [])

  return (
    <div>
      <Helmet>
        <title>Accidents requalifiés</title>
      </Helmet>
      <HeaderBar
        title={
          <>
            Accidents requalifiés
          </>
        }
        sticky={true} smaller={true}
      >
        <input
          type="text"
          name="query"
          value={query}
          className="mb-0 appearance-none bg-transparent border-b border-white w-full py-2  leading-tight focus:outline-none focus:border-red"
          placeholder="Rechercher un accident"
          onChange={(e) => setQuery(e.target.value)}
        />
      </HeaderBar>
      <div>
        <div className="px-8 mt-8 mb-4">
          <button
            className="flex items-center"
            onClick={() => toggleFilters(true)}
          >
            <FilterIcon />
            <span className="ml-4">Filtres</span>
          </button>
          <Modal
            title="Filtres"
            handleClose={(e) => {
              e.stopPropagation();
              toggleFilters(false);
            }}
            isOpen={FiltersOpen}
            onRequestClose={() => toggleFilters(false)}
          >
            <div className="-mx-4 px-5">
              <div className="font-black mb-3">Sélectionner une période</div>
              <hr className="my-2" />
              <div className="text-center">
                <DatePicker
                  selected={dateRange.startDate}
                  onChange={([startDate, endDate]) => {
                    setDateRange({
                      startDate: startDate,
                      endDate: endDate,
                    });
                  }}
                  startDate={dateRange.startDate}
                  endDate={dateRange.endDate}
                  selectsRange
                  inline
                />
              </div>
              {map(filtersList, ({ key, label, options }) => {
                return (
                  <CheckboxGroup
                    key={key}
                    label={label}
                    options={options}
                    className="py-4 odd:bg-purple-50 -mx-4 px-5"
                    selected={get(filters, key, [])}
                    onAdd={(option) => {
                      setFilter(key, [...get(filters, key, []), option]);
                    }}
                    onRemove={(option) => {
                      const newFilters = pull(get(filters, key, []), option);
                      if (size(newFilters) === 0) {
                        removeFilter(key);
                        return;
                      }
                      setFilter(key, newFilters);
                    }}
                  />
                );
              })}
            </div>

            <div>
              <div
                className="mt-3 underline cursor-pointer"
                onClick={() => {
                  resetFilters();
                  setDateRange({
                    startDate: new Date(),
                    endDate: null,
                  });
                }}
              >
                Supprimer les filtres
              </div>
            </div>

            <button
              className="btn mt-5 w-full"
              type="button"
              onClick={() => toggleFilters(false)}
            >
              Appliquer les filtres
            </button>
          </Modal>
        </div>
        <div className="px-8 mb-48">
          <React.Suspense fallback={<Spinner />}>
            <RequalifiedWorkaccidentsList
              query={debouncedQuery}
              filters={filters}
              dateRange={dateRange}
            />
          </React.Suspense>
        </div>
      </div>
    </div>
  );
}

export default RequalifiedWorkaccidents;
